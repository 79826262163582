import {useContext} from 'react'
import PopupMessageContext from '../context/popup_message/PopupMessageProvider'

/**
 * Accessor for the error context.
 * @returns {{}} ErrorContext export values
 */
function usePopupMessage() {
    return useContext(PopupMessageContext)
}

export default usePopupMessage