/**
 * Logs user in.
 * Returns an error as string if something went wrong
 * @param username {String} Name of user, who needs to be logged in
 * @param password {String} Password of the user (hashed)
 * @returns {Promise<Object | String>}
 */
function loginRequest(username, password) {
    return fetch(window.api + '/api/login', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            username: username,
            password: password
        })
    }).then((response) => {
        return response.json().then(data => {
            if (response.ok) {
                return {
                    username: data.username,
                    token: data.token,
                    type: data.type
                }
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

export {loginRequest}