import {useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import style from './Login.module.css'
import useAuth from '../../components/hooks/useAuth'
import {loginRequest} from '../../accessors/LoginAccessor'
import {hash} from '../../util/Hashing'
import logo from '../../images/logo.svg'

/**
 * Show an error message.
 * Not the same as ErrorProvider.
 * Messages are shown under login button with animation.
 * @param message {String} Message that needs to be shown
 * @constructor
 */
function Message({message}) {
    if (!message) return
    return <p className={style.message}>{message}</p>
}

/**
 * Creates the page for the login
 * @returns {JSX.Element} Page with login
 * @constructor
 */
function Login() {
    const {auth, login} = useAuth()
    const navigate = useNavigate()

    const [message, setMessage] = useState('')

    // Navigates an already logged-in user to the right page
    useEffect(() => {
        if (auth?.type) {
            navigate('/' + auth?.type)
        }
    }, [])

    /**
     * Sends login request to backend and logs user in, if successful
     * @param event {SubmitEvent} Triggered when user presses login button
     */
    function handleSubmit(event) {
        setMessage('')
        event.preventDefault()
        hash(event.target.elements.password.value).then((hashedPwd) => {
            loginRequest(event.target.elements.username.value, hashedPwd).then(data => {
                if (typeof data === 'string') {
                    setMessage(data)
                } else {
                    login(data)
                    navigate('/' + data.type)
                }
            })
        })
    }

    return (
        <>
            <div>
                <img className={style.homeButton} src={logo} alt={'Bingo'} onClick={() => navigate('/bingo')}/>
            </div>
            <div className={style.base}>
                <div className={style.container}>
                    <h1 className={style.header}>Login</h1>
                    <form className={style.form} onSubmit={handleSubmit}>
                        <input type='username' name='username' placeholder='Name'/>
                        <input type='password' name='password' placeholder='Password' autoComplete="off"/>
                        <button type="submit" className={style.submit}>Login</button>
                    </form>
                    <Message message={message}/>
                </div>
            </div>
        </>
    )
}

export default Login