import stylePopup from '../Popup.module.css'
import {useEffect, useRef, useState} from 'react'
import {addWordRequest} from '../../../accessors/AdminAccessor'
import usePopupMessage from '../../hooks/usePopupMessage'
import useAuth from '../../hooks/useAuth'
import {PopupMessageType} from '../../../util/enums'

/**
 * A Component to show a popup, which lets an admin add a word.
 * @param word {Object} Starting values
 * @param word.id {Number} ID of word
 * @param word.word {String} The word
 * @param word.category {String} The category of the word
 * @param hide {Function} Function to hide the popup
 * @param refresh {Function} Function to rerender the page
 * @returns {JSX.Element} AddWordPopup component
 * @constructor
 */
function AddWordPopup({word, hide, refresh}) {
    const {auth} = useAuth()
    const {showMessage} = usePopupMessage()

    const wordRef = useRef()
    const categoryRef = useRef()

    const [warning, setWarning] = useState(null)

    // Set starting values
    useEffect(() => {
        wordRef.current.value = word.word
        categoryRef.current.value = word.category
    }, [])

    /**
     * Check for correct values everytime a value is changed
     */
    function valueChanged() {
        if (!wordRef.current.value) {
            setWarning('Kein Wort angegeben')
        } else if (!categoryRef.current.value) {
            setWarning('Keine Kategorie angegeben')
        } else {
            setWarning(null)
        }
    }

    /**
     * Sends request to backend.
     * Show status message on error or success.
     */
    function addWord() {
        addWordRequest(auth, wordRef.current.value, categoryRef.current.value).then((result) => {
            if (typeof result === 'string') {
                showMessage(result, PopupMessageType.ERROR)
            } else {
                showMessage('Wort hinzugefügt', PopupMessageType.SUCCESS)
                hide() // Close popup
                refresh() // Rerender
            }
        })
    }

    return (
        <>
            <h1>Wort hinzufügen</h1>
            <div className={stylePopup.values}>
                <input placeholder={'Wort'} type='text' ref={wordRef} onChange={valueChanged}/>
                <input placeholder={'Kategorie'} type='text' ref={categoryRef} onChange={valueChanged}/>
            </div>
            {warning && <label className={stylePopup.warning}>{warning}</label>}
            <div className={stylePopup.submitButtons}>
                <button className={stylePopup.cancel} onClick={hide}>Abbrechen</button>
                <button className={stylePopup.submit} disabled={!!warning} onClick={addWord}>Hinzufügen</button>
            </div>
        </>
    )
}

export default AddWordPopup