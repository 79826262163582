import {useContext} from 'react'
import BoardHeightContext from '../context/bingo/BoardHeightProvider'

/**
 * Accessor for the bingo board height context.
 * @returns {{}} BoardHeightContext export values
 */
function useBoardHeight() {
    return useContext(BoardHeightContext)
}

export default useBoardHeight