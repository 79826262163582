import stylePopup from '../Popup.module.css'
import {deleteSuggestionRequest} from '../../../accessors/AdminAccessor'
import usePopupMessage from '../../hooks/usePopupMessage'
import useAuth from '../../hooks/useAuth'
import {PopupMessageType} from '../../../util/enums'

/**
 * A Component to show a popup, which lets an admin delete a suggestion.
 * @param suggestion {Object} Starting values
 * @param suggestion.id {Number} ID of suggestion
 * @param suggestion.word {String} Suggested word
 * @param suggestion.count {Number} Count of suggestions for word
 * @param hide {Function} Function to hide the popup
 * @param refresh {Function} Function to rerender the page
 * @returns {JSX.Element} DeleteSuggestionPopup component
 * @constructor
 */
function DeleteSuggestionPopup({suggestion, hide, refresh}) {
    const {auth} = useAuth()
    const {showMessage} = usePopupMessage()

    /**
     * Sends request to backend.
     * Show status message on error or success.
     */
    function deleteWord() {
        deleteSuggestionRequest(auth, suggestion.id).then((result) => {
            if (typeof result === 'string') {
                showMessage(result, PopupMessageType.ERROR)
            } else {
                showMessage('Vorschlag gelöscht', PopupMessageType.SUCCESS)
                hide() // Close popup
                refresh() // Rerender
            }
        })
    }

    return (
        <>
            <h1>Vorschlag löschen</h1>
            <p>Möchtest du diesen Vorschlag löschen?</p>
            <p>{suggestion.word}</p>
            <div className={stylePopup.submitButtons}>
                <button className={stylePopup.cancel} onClick={hide}>Abbrechen</button>
                <button className={stylePopup.submit} onClick={deleteWord}>Löschen</button>
            </div>
        </>
    )
}

export default DeleteSuggestionPopup