import {useContext} from 'react'
import AuthContext from '../context/auth/AuthProvider'

/**
 * Accessor for the authentication context.
 * @returns {{}} AuthContext export values
 */
function useAuth() {
    return useContext(AuthContext)
}

export default useAuth