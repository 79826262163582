/**
 * Generates a not-random number from the seed.
 * @param seed {number} the seed for the 'random' generator
 * @returns {number} The generated number
 */
function random(seed) {
    let x = Math.sin(seed++) * 10000
    return x - Math.floor(x)
}

/**
 * Generates a random seed for shuffling.
 * @returns {number}
 */
function randomSeed() {
    let randomSeed = ''
    for (let i = 0; i < 19; i++) {
        randomSeed += Math.floor(Math.random() * 10)
    }

    return Number(randomSeed)
}

export {random, randomSeed}