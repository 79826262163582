import {createContext, useEffect, useState} from 'react'

const BoardStateContext = createContext({})

/**
 * A provider context for the state of the bingo board.
 * <p>
 * Usage: <BoardStateProvider> ... </BoardStateProvider>
 * @param children {JSX.Element | JSX.Element[]} The components inside this context
 * @returns {JSX.Element} Provider Component for state of bingo board
 * @constructor
 */
export function BoardStateProvider({children}) {
    const [state, setState] = useState([])
    const [bingo, setBingo] = useState(false)

    // Check if bingo is achieved, every time a state changes
    useEffect(() => {
        if (state.length <= 0) return

        const size = Math.sqrt(state.length)
        let bingoRowColumn = false

        // Check rows and columns
        for (let i = 0; i < size; i++) {
            let bingoRow = true
            let bingoColumn = true

            for (let j = 0; j < size; j++) {
                // Row
                if (!state[i * size + j]) {
                    bingoRow = false
                }
                // Column
                if (!state[j * size + i]) {
                    bingoColumn = false
                }
            }

            if (bingoRow || bingoColumn) {
                bingoRowColumn = true
                break
            }
        }

        // Check diagonals
        let bingoDiagonal1 = true
        let bingoDiagonal2 = true

        for (let i = 0; i < size; i++) {
            if (!state[i * size + i]) {
                bingoDiagonal1 = false
            }
            if (!state[i * size + (size - i - 1)]) {
                bingoDiagonal2 = false
            }
        }

        setBingo(bingoRowColumn || bingoDiagonal1 || bingoDiagonal2)
    }, [state])

    /**
     * Initiate the state array
     * @param startState {Boolean[]} The initial array (all values should be false
     */
    function initState(startState) {
        setState(startState)
    }

    /**
     * Updates the state for a cell
     * @param i {Number} Index of cell
     * @param cellState {Boolean} Value if Cell is clicked
     */
    function updateCellState(i, cellState) {
        const currentState = [...state]
        currentState[i] = cellState
        setState(currentState)
    }

    return (
        <BoardStateContext.Provider value={{state, bingo, initState, updateCellState}}>
            {children}
        </BoardStateContext.Provider>
    )
}

export default BoardStateContext