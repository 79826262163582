import {useEffect, useRef, useState} from 'react'
import {getWordsWithIdsRequest} from '../../accessors/AdminAccessor'
import usePopupMessage from '../../components/hooks/usePopupMessage'
import useAuth from '../../components/hooks/useAuth'
import style from './Words.module.css'
import Popup from '../../components/popup/Popup'
import {PopupMessageType, PopupType} from '../../util/enums'

/**
 * The page which shows all words (only for admin).
 * @returns {JSX.Element} Words page component
 * @constructor
 */
function Words() {
    const {showMessage} = usePopupMessage()
    const {auth} = useAuth()

    const popupRef = useRef('')

    const [words, setWords] = useState([])
    const [show, setShow] = useState(false)
    const [wordPopupData, setWordPopupData] = useState({id: -1, word: ''})

    // Set show popup to true or false
    const handleHide = () => setShow(false)
    const handleShow = () => setShow(true)

    // Load words on start
    useEffect(() => {
        refreshWords()
    }, [])

    /**
     * Load words from backend.
     */
    function refreshWords() {
        getWordsWithIdsRequest(auth).then((wordsFromBackend) => {
            if (typeof wordsFromBackend === 'string') {
                showMessage(wordsFromBackend, PopupMessageType.ERROR)
            } else {
                setWords(wordsFromBackend)
            }
        })
    }

    /**
     * Set which popup is shown.
     * @param popup {PopupType} The popup type
     * @param word {Object} The data to fill the inputs
     * @param word.id {Number} ID of the word
     * @param word.word {String} The word
     * @param word.category {String} The category of the word
     */
    const setPopup = (popup, word) => {
        popupRef.current = popup
        setWordPopupData(word)
        handleShow()
    }

    /**
     * Sets the current popup to add a word.
     */
    function addWord() {
        setPopup(PopupType.ADD_WORD, {id: -1, word: '', category: ''})
    }

    /**
     * Sets the current popup to edit a word.
     * @param word {Object} The data to fill the inputs
     * @param word.id {Number} ID of the word
     * @param word.word {String} The word
     * @param word.category {String} The category of the word
     */
    function editWord(word) {
        setPopup(PopupType.EDIT_WORD, word)
    }

    /**
     * Sets the current popup to delete a word.
     * @param word {Object} The data to fill the inputs
     * @param word.id {Number} ID of the word
     * @param word.word {String} The word
     * @param word.category {String} The category of the word
     */
    function deleteWord(word) {
        setPopup(PopupType.DELETE_WORD, word)
    }

    /**
     * Generates the rows with buttons for the words table.
     * @returns {JSX.Element[] | undefined} undefined if words is not loaded from backend
     * @constructor
     */
    function WordRows() {
        return words?.map((word) => (
            <tr key={word.id}>
                <td>{word.word}</td>
                <td>{word.category}</td>
                <td>
                    <div className={style.buttonCell}>
                        <button onClick={() => editWord(word)}
                            style={{'borderColor': 'var(--green-color)'}}>Bearbeiten
                        </button>
                        <button onClick={() => deleteWord(word)} style={{'borderColor': 'var(--red-color)'}}>Löschen
                        </button>
                    </div>
                </td>
            </tr>
        ))
    }

    return (
        <>
            <div className={style.words}>
                <table className={style.admin_words_table}>
                    <thead>
                        <tr>
                            <th>Wort</th>
                            <th>Kategorie</th>
                            <th>
                                <div className={style.buttonCellHeader}>
                                    <button onClick={addWord}>Hinzufügen</button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <WordRows/>
                    </tbody>
                </table>
            </div>
            <Popup show={show} handleHide={handleHide} popupData={wordPopupData} popupRef={popupRef}
                refreshFunction={refreshWords}/>
        </>
    )
}

export default Words