import {useState} from 'react'
import useWords from '../hooks/useWords'
import style from './Checkbox.module.css'

/**
 * A Checkbox to set a word active or not active.
 * @param wordObject {Object} The word object
 * @param wordObject.word {String} The word
 * @param wordObject.active {Boolean} States if word is active or not
 * @param id {String} Any id for the input-label pair
 * @returns {JSX.Element} Checkbox component
 * @constructor
 */
function Checkbox({wordObject, id}) {
    const {setActive} = useWords()

    const [checked, setChecked] = useState(wordObject.active)

    function toggleChecked() {
        setActive(wordObject.word, !checked)

        setChecked(!checked)
    }

    return (
        <div className={style.container}>
            <input type={'checkbox'} onChange={toggleChecked} id={id} checked={checked}/>
            <label htmlFor={id}></label>
        </div>
    )
}

export default Checkbox