import style from './Accounts.module.css'
import Popup from '../../components/popup/Popup'
import usePopupMessage from '../../components/hooks/usePopupMessage'
import useAuth from '../../components/hooks/useAuth'
import {useEffect, useRef, useState} from 'react'
import {getAccountsRequest} from '../../accessors/AdminAccessor'
import {PopupMessageType, PopupType} from '../../util/enums'

function Accounts() {
    const {showMessage} = usePopupMessage()
    const {auth} = useAuth()

    const popupRef = useRef('')

    const [accounts, setAccounts] = useState([])
    const [show, setShow] = useState(false)
    const [accountPopupData, setAccountPopupData] = useState({id: -1, username: '', type: ''})

    // Set show popup to true or false
    const handleHide = () => setShow(false)
    const handleShow = () => setShow(true)

    // Load accounts on start
    useEffect(() => {
        refreshAccounts()
    }, [])

    /**
     * Load accounts from backend.
     */
    function refreshAccounts() {
        getAccountsRequest(auth).then((accountsFromBackend) => {
            if (typeof accountsFromBackend === 'string') {
                showMessage(accountsFromBackend, PopupMessageType.ERROR)
            } else {
                setAccounts(accountsFromBackend)
            }
        })
    }

    /**
     * Set which popup is shown.
     * @param popup {PopupType} The popup type
     * @param account {Object} The data to fill the inputs
     * @param account.id {Number} ID of account
     * @param account.username {String} Username of account
     * @param account.type {String} Type of account
     */
    const setPopup = (popup, account) => {
        popupRef.current = popup
        setAccountPopupData(account)
        handleShow()
    }

    /**
     * Sets the current popup to add an account.
     */
    function addAccount() {
        setPopup(PopupType.ADD_ACCOUNT, {id: -1, username: '', type: 'admin'})
    }

    /**
     * Sets the current popup to edit an account.
     * @param account {Object} The data to fill the inputs
     * @param account.id {Number} ID of account
     * @param account.username {String} Username of account
     * @param account.type {String} Type of account
     */
    function editAccount(account) {
        setPopup(PopupType.EDIT_ACCOUNT, account)
    }

    /**
     * Sets the current popup to delete an account.
     * @param account {Object} The data to fill the inputs
     * @param account.id {Number} ID of account
     * @param account.username {String} Username of account
     * @param account.type {String} Type of account
     */
    function deleteAccount(account) {
        setPopup(PopupType.DELETE_ACCOUNT, account)
    }

    /**
     * Generates the rows with buttons for the accounts table.
     * @returns {JSX.Element[] | undefined} undefined if accounts is not loaded from backend
     * @constructor
     */
    function AccountRows() {
        return accounts?.map((account) => (
            <tr key={account.id}>
                <td>{account.username}</td>
                <td>{account.type}</td>
                <td>
                    <div className={style.buttonCell}>
                        <button onClick={() => editAccount(account)}
                            style={{'borderColor': 'var(--green-color)'}}>Bearbeiten
                        </button>
                        <button onClick={() => deleteAccount(account)}
                            style={{'borderColor': 'var(--red-color)'}}>Löschen
                        </button>
                    </div>
                </td>
            </tr>
        ))
    }

    return (
        <>
            <div className={style.accounts}>
                <table className={style.admin_accounts_table}>
                    <thead>
                        <tr>
                            <th>Benutzername</th>
                            <th>Typ</th>
                            <th>
                                <div className={style.buttonCellHeader}>
                                    <button onClick={addAccount}>Hinzufügen</button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <AccountRows/>
                    </tbody>
                </table>
            </div>
            <Popup show={show} handleHide={handleHide} popupData={accountPopupData} popupRef={popupRef}
                refreshFunction={refreshAccounts}/>
        </>
    )
}

export default Accounts