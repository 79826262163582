import style from './Cell.module.css'
import {useEffect, useState} from 'react'
import xImage from '../../../images/X.svg'
import {useResizeDetector} from 'react-resize-detector'
import useWords from '../../hooks/useWords'
import useBingoState from '../../hooks/useBingoState'

/**
 * A cell of a bingo board.
 * Does have a word and can be clicked to place an "☒".
 * @param index {Number} The position of the cell in the board, so the word can be assigned
 * @returns {JSX.Element} Cell component
 * @constructor
 */
function Cell({index}) {
    const {currentWords} = useWords()
    const {updateCellState} = useBingoState()
    const {width, height, ref} = useResizeDetector()

    const [isClicked, setIsClicked] = useState(false)
    const [fontSize, setFontSize] = useState(20)
    const [word, setWord] = useState('')

    useEffect(() => {
        resizeText()
    }, [word, width, height])

    useEffect(() => {
        setIsClicked(false)
        setWord(currentWords[index] ?? ' ')
    }, [currentWords])

    // Change font size so text fits into cell
    const resizeText = () => {
        if (!word || !width) return
        if (word === '') return

        const minSize = 5

        // Get length of longest word
        const length = word.split(' ').reduce(function (a, b) {
            return a.length > b.length ? a : b
        }, '').length

        setFontSize(Math.max((width / (length !== 0 ? length : 1)) * 1.5, minSize))
    }

    const toggleClicked = () => {
        setIsClicked(!isClicked)
        updateCellState(index, !isClicked)
    }

    return (
        <td onClick={toggleClicked}>
            <div ref={ref} className={style.content}>
                <div className={style.child}>
                    <label className={style.label} style={{fontSize: fontSize}}>{word}</label>
                </div>
                <div className={style.child}>
                    <img className={style.image} draggable="false" style={{display: isClicked ? 'block' : 'none'}}
                        src={xImage} alt={''}/>
                </div>
            </div>
        </td>
    )
}

export default Cell