import style from './Bingo.module.css'
import {getWordsRequest, suggestWordRequest} from '../../accessors/BingoAccessor'
import {useEffect, useState} from 'react'
import usePopupMessage from '../../components/hooks/usePopupMessage'
import useWords from '../../components/hooks/useWords'
import BingoBoard from '../../components/bingo/bingo_board/BingoBoard'
import WordsTable from '../../components/bingo/words_table/WordsTable'
import {BoardHeightProvider} from '../../components/context/bingo/BoardHeightProvider'
import {random, randomSeed} from '../../util/Random'
import clipboardImage from '../../images/clipboard.svg'
import {PopupMessageType} from '../../util/enums'
import Dropdown from '../../components/dropdown/Dropdown'
import useBingoState from '../../components/hooks/useBingoState'

/**
 * Creates the page for the bingo board
 * @returns {JSX.Element} Page with bingo board and word table (+ suggestions)
 * @constructor
 */
function Bingo() {
    const {showMessage} = usePopupMessage()
    const {bingo, initState} = useBingoState()
    const {
        size,
        allWords,
        activeWords,
        rerender,
        setSize,
        setAllWords,
        setActiveWords,
        setCurrentWords,
        setCategory,
        getActiveId,
        updateAllActiveWords
    } = useWords()

    const [currentActiveId, setCurrentActiveId] = useState('')
    const [currentSeed, setSeed] = useState(0)
    const [categories, setCategories] = useState([])

    const selectedSize = 5 // Maybe more board sizes later

    // Receive words from backend on page loading
    useEffect(() => {
        setSize(selectedSize)

        getWordsRequest().then(wordsFromBackend => {
            if (typeof wordsFromBackend === 'string') {
                showMessage(wordsFromBackend, PopupMessageType.ERROR)
            } else if (wordsFromBackend.length < size * size) {
                showMessage('Nicht genug Wörter vorhanden', PopupMessageType.ERROR)
            } else {
                // Save all Words with category as active
                setAllWords(wordsFromBackend.map(word => {
                    return {
                        word: word.word,
                        category: word.category,
                        active: true
                    }
                }))
                // Set the active words
                setActiveWords(wordsFromBackend.map((word) => word.word))
                // Generate ID for all active words
                setCurrentActiveId(parseInt(Array(wordsFromBackend.length).fill(1).join(''), 2).toString(16))
                // Reduce categories (Each category only one) and add 'all' category
                setCategories([{ // All category at start
                    text: 'Alle',
                    value: ''
                }].concat(wordsFromBackend.map(word =>  { // Map words to correct format for dropdown (Dropdown needs text and value attributes)
                    return {
                        text: word.category,
                        value: word.category
                    }
                }).filter((value, index, self) => { // Reduce to each category only once
                    return index === self.findIndex((t) => (t.value === value.value))
                })))
                // Shuffle words and generate first board
                shuffle(wordsFromBackend)
                setCurrentWords(wordsFromBackend.map((word) => word.word))

                // Init state of cells
                initState(Array(size * size).fill(false))
            }
        })
    }, [])

    // Renders the board with now the given active words and seed from the board id
    useEffect(() => {
        if (!activeWords || activeWords.length === 0) return

        updateBingoWords(currentSeed)

        showMessage('Bingo-Board wurde geladen', PopupMessageType.SUCCESS)
    }, [rerender])

    useEffect(() => {
        // TODO: Was cooles anzeigen
    }, [bingo])

    /**
     * Shuffles an array.
     * @param array {*[]} Array to shuffle
     * @param seed {number} the seed for the random generator
     * @returns {*[]} Shuffled array
     */
    function shuffle(array, seed = undefined) {
        let m = array.length, t, i

        if (!seed) {
            seed = randomSeed()
            setSeed(seed)
        }

        // While there remain elements to shuffle…
        while (m) {

            // Pick a remaining element…
            i = Math.floor(random(seed) * m--)

            // And swap it with the current element.
            t = array[m]
            array[m] = array[i]
            array[i] = t
            ++seed
        }

        return array
    }

    /**
     * Reshuffle the words and load new bingo board.
     * @param seed {Number} The seed to shuffle the board with
     */
    function updateBingoWords(seed = undefined) {
        if (!activeWords) return

        const words = [...activeWords]

        if (activeWords.length < size * size) {
            // Not enough words for a bingo board selected
            showMessage('Nicht genug aktive Wörter', PopupMessageType.ERROR)
        } else {
            shuffle(words, seed)
            setCurrentWords(words)
            setCurrentActiveId(getActiveId())
        }
    }

    /**
     * Submit a word request to the backend
     * @param event {SubmitEvent} Triggered when the submit button is pressed
     */
    function suggestWord(event) {
        event.preventDefault()

        const word = event.target.elements.suggest_word.value

        if (word === '') {
            showMessage('Schlage bitte ein Wort vor', PopupMessageType.ERROR)
            return
        }

        suggestWordRequest(word).then((result) => {
            if (typeof result === 'string') {
                showMessage(result, PopupMessageType.ERROR)
            } else {
                showMessage('Wort wurde vorgeschlagen', PopupMessageType.SUCCESS)
                event.target.elements.suggest_word.value = ''
            }
        })
    }

    /**
     * Gets an id for a bingo board and regenerates this board
     * @param event {SubmitEvent} Triggered when the submit button is pressed
     */
    function generateBoardFromId(event) {
        event.preventDefault()

        // Regex for allowed ids (<hex-number>&<hex-number>)
        const regex = /[0-9a-fA-F]+&[0-9a-fA-F]+/

        const id = event.target.elements.board_id.value

        if (!regex.exec(id)) {
            showMessage('Die ID hat nicht das richtige Format', PopupMessageType.ERROR)
            return
        }

        // Transforms activeId to binary and fills with zeros if there are more words than for this id
        const activeId = parseInt(id.split('&')[0], 16).toString(2).padStart(allWords.length, '0')
        setSeed(parseInt(id.split('&')[1], 16))

        updateAllActiveWords(activeId)
    }

    /**
     * Copies the current board id to the clipboard.
     */
    function copyToClipboard() {
        navigator.clipboard.writeText(currentActiveId + '&' + currentSeed.toString(16)).then(() => {
            showMessage('ID kopiert', PopupMessageType.SUCCESS)
        }).catch((err) => {
            console.log(err)
            console.error()
            showMessage('ID konnte nicht kopiert werden', PopupMessageType.ERROR)
        })
    }

    return (
        <BoardHeightProvider>
            <div className={style.base}>
                <div className={style.container}>
                    <div className={style.top_text}>
                        Deine Board-ID: {currentActiveId + '&' + currentSeed.toString(16)}
                        <img className={style.copy_image} draggable="false" src={clipboardImage} alt={''}
                            onClick={copyToClipboard}/>
                    </div>
                    <div className={style.top_dropdown}>
                        Kategorie:
                        <Dropdown options={categories} onChange={(currentCategory) => setCategory(currentCategory)} value={''}/>
                    </div>
                    <div className={style.bingo}>
                        <BingoBoard/>
                    </div>
                    <div className={style.words}>
                        <WordsTable/>
                    </div>
                    <div className={style.bingo_tools}>
                        <div>
                            <button onClick={() => updateBingoWords()}>Neues Board</button>
                        </div>
                        <form className={style.form} onSubmit={generateBoardFromId}>
                            <input type={'text'} name={'board_id'} placeholder={'ID eingeben'}/>
                            <button>Board generieren</button>
                        </form>
                    </div>
                    <div className={style.word_tools}>
                        <div>
                            Aktuell aktive Wörter: {activeWords?.length}
                        </div>
                        <form className={style.form} onSubmit={suggestWord}>
                            <input type={'text'} name={'suggest_word'} placeholder={'Wort eingeben'}/>
                            <button type={'submit'}>Wort vorschlagen</button>
                        </form>
                    </div>
                </div>
            </div>
        </BoardHeightProvider>
    )
}


export default Bingo