import style from './Popup.module.css'
import AddWordPopup from './words/AddWordPopup'
import EditWordPopup from './words/EditWordPopup'
import DeleteWordPopup from './words/DeleteWordPopup'
import AddSuggestionPopup from './suggestions/AddSuggestionPopup'
import EditSuggestionPopup from './suggestions/EditSuggestionPopup'
import SuggestionToWordPopup from './suggestions/SuggestionToWordPopup'
import DeleteSuggestionPopup from './suggestions/DeleteSuggestionPopup'
import AddAccountPopup from './account/AddAccountPopup'
import EditAccountPopup from './account/EditAccountPopup'
import DeleteAccountPopup from './account/DeleteAccountPopup'
import {PopupType} from '../../util/enums'

/**
 * The container component for all popups.
 * @param show {Boolean} Ture if popup is currently shown
 * @param handleHide {Function} Function to hide the popup
 * @param popupData {Object} The data for a popup
 * @param popupRef {MutableRefObject} The type of the current popup
 * @param refreshFunction {Function} Function to rerender the page
 * @returns {JSX.Element} Popup component
 * @constructor
 */
function Popup({show, handleHide, popupData, popupRef, refreshFunction}) {
    const types = [
        {
            text: 'Admin',
            value: 'admin'
        }
    ]

    /**
     * Determines which popup is shown based of the type.
     * @constructor
     */
    function CurrentPopup() {
        if (!popupData) return

        switch (popupRef.current) {
            case PopupType.ADD_WORD:
                return (<AddWordPopup word={popupData} hide={handleHide} refresh={refreshFunction}/>)
            case PopupType.EDIT_WORD:
                return (<EditWordPopup word={popupData} hide={handleHide} refresh={refreshFunction}/>)
            case PopupType.DELETE_WORD:
                return (<DeleteWordPopup word={popupData} hide={handleHide} refresh={refreshFunction}/>)
            case PopupType.ADD_SUGGESTION:
                return (<AddSuggestionPopup suggestion={popupData} hide={handleHide} refresh={refreshFunction}/>)
            case PopupType.ADD_TO_WORDS:
                return (<SuggestionToWordPopup suggestion={popupData} hide={handleHide} refresh={refreshFunction}/>)
            case PopupType.EDIT_SUGGESTION:
                return (<EditSuggestionPopup suggestion={popupData} hide={handleHide} refresh={refreshFunction}/>)
            case PopupType.DELETE_SUGGESTION:
                return (<DeleteSuggestionPopup suggestion={popupData} hide={handleHide} refresh={refreshFunction}/>)
            case PopupType.ADD_ACCOUNT:
                return (<AddAccountPopup account={popupData} typeOptions={types} hide={handleHide}
                    refresh={refreshFunction}/>)
            case PopupType.EDIT_ACCOUNT:
                return (<EditAccountPopup account={popupData} typeOptions={types} hide={handleHide}
                    refresh={refreshFunction}/>)
            case PopupType.DELETE_ACCOUNT:
                return (<DeleteAccountPopup account={popupData} hide={handleHide} refresh={refreshFunction}/>)
        }
    }

    return (
        <div className={style.popupBase} style={{display: show ? 'flex' : 'none'}}>
            <div className={style.popupContainer}>
                <CurrentPopup/>
            </div>
        </div>
    )
}

export default Popup