import useWords from '../../hooks/useWords'
import Checkbox from '../../checkbox/Checkbox'
import style from './WordsTable.module.css'
import useBoardHeight from '../../hooks/useBoardHeight'

/**
 * A Table where all available words are shown.
 * They can be activated or deactivated.
 * @returns {JSX.Element} WordsTable Component
 * @constructor
 */
function WordsTable() {
    const {allWords, rerender, category} = useWords()
    const {height} = useBoardHeight()

    /**
     * Creates the rows with the words and checkboxes.
     * @returns {JSX.Element[]} The rows as an array
     * @constructor
     */
    function WordRows() {
        // All category selected -> Show all words
        if (category.value === '') {
            return allWords?.map((word, i) => (
                <tr key={i}>
                    <td>{word.word}</td>
                    <td><Checkbox wordObject={word} id={'checkbox' + i}/></td>
                </tr>
            ))
        }

        // Other category selected -> Show only words from that category
        return allWords?.filter((word) => word.category === category.value).map((word, i) => (
            <tr key={i}>
                <td>{word.word}</td>
                <td><Checkbox wordObject={word} id={'checkbox' + i}/></td>
            </tr>
        ))
    }

    return (
        <table id={rerender.toString()} className={style.words_table} style={{height: `${height + 5}px`}}>
            <thead>
                <tr>
                    <th>Wort</th>
                    <th>Aktiv</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                <WordRows/>
            </tbody>
        </table>
    )
}

export default WordsTable