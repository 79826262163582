/**
 * Get all words for admin.
 * Returns an error as string if something went wrong.
 * @param auth {Object} The object containing all auth information
 * @param auth.username {String} Username of logged-in user
 * @param auth.token {String} Generated token from backend
 * @param auth.type {String} Type of logged-in user
 * @returns {Promise<String[] | String>} List of all words or error
 */
function getWordsWithIdsRequest(auth) {
    return fetch(window.api + `/api/admin/words?requestingUser=${auth?.username}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        }
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return data.words
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Sends a word to the backend to add to the database.
 * Returns an error as string if something went wrong.
 * @param auth {Object} The object containing all auth information
 * @param auth.username {String} Username of logged-in user
 * @param auth.token {String} Generated token from backend
 * @param auth.type {String} Type of logged-in user
 * @param word {String} The word that is being added
 * @param category {String} The category of the word
 * @returns {Promise<void | String>} void if action was successful
 */
function addWordRequest(auth, word, category) {
    return fetch(window.api + `/api/admin/words/add?requestingUser=${auth?.username}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        },
        body: JSON.stringify({
            word: word,
            category: category
        })
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Sends an edit request for a word to the backend to edit in the database.
 * Returns an error as string if something went wrong.
 * @param auth {Object} The object containing all auth information
 * @param auth.username {String} Username of logged-in user
 * @param auth.token {String} Generated token from backend
 * @param auth.type {String} Type of logged-in user
 * @param id {Number} The id of the word
 * @param word {String} The changed word
 * @param category {String} The category of the word
 * @returns {Promise<void | String>} void if action was successful
 */
function editWordRequest(auth, id, word, category) {
    return fetch(window.api + `/api/admin/words/edit?requestingUser=${auth?.username}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        },
        body: JSON.stringify({
            id: id,
            word: word,
            category: category
        })
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Sends a delete request for a word to the backend to delete it from the database.
 * Returns an error as string if something went wrong.
 * @param auth {Object} The object containing all auth information
 * @param auth.username {String} Username of logged-in user
 * @param auth.token {String} Generated token from backend
 * @param auth.type {String} Type of logged-in user
 * @param id {Number} The id of the word
 * @returns {Promise<void | String>} void if action was successful
 */
function deleteWordRequest(auth, id) {
    return fetch(window.api + `/api/admin/words/delete?requestingUser=${auth?.username}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        },
        body: JSON.stringify({
            id: id
        })
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Get all suggestions for admin.
 * Returns an error as string if something went wrong.
 * @param auth {Object}
 * @param auth.username {String}
 * @param auth.type {String}
 * @param auth.token {String}
 * @returns {Promise<String[] | String>}
 */
function getSuggestionsWithIdsRequest(auth) {
    return fetch(window.api + `/api/admin/suggestions?requestingUser=${auth?.username}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        }
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return data.suggestions
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Sends a suggestion to the backend to add to the database.
 * Returns an error as string if something went wrong.
 * @param auth {Object} The object containing all auth information
 * @param auth.username {String} Username of logged-in user
 * @param auth.token {String} Generated token from backend
 * @param auth.type {String} Type of logged-in user
 * @param word {String} The word that is being added
 * @param count {Number} The count of suggestions for that word
 * @returns {Promise<void | String>} void if action was successful
 */
function addSuggestionRequest(auth, word, count) {
    return fetch(window.api + `/api/admin/suggestions/add?requestingUser=${auth?.username}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        },
        body: JSON.stringify({
            word: word,
            count: count
        })
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Sends a suggestion to the backend to convert to a word.
 * Returns an error as string if something went wrong.
 * @param auth {Object} The object containing all auth information
 * @param auth.username {String} Username of logged-in user
 * @param auth.token {String} Generated token from backend
 * @param auth.type {String} Type of logged-in user
 * @param id {Number} The id of the suggestion
 * @param word {String} The suggestion that is being converted to a word (may differ from database)
 * @param category {String} The category of the word
 * @returns {Promise<void | String>} void if action was successful
 */
function addSuggestionToWordsRequest(auth, id, word, category) {
    return fetch(window.api + `/api/admin/suggestions/to_word?requestingUser=${auth?.username}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        },
        body: JSON.stringify({
            id: id,
            word: word,
            category: category
        })
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Sends an edit request for a suggestion to the backend to edit in the database.
 * Returns an error as string if something went wrong.
 * @param auth {Object} The object containing all auth information
 * @param auth.username {String} Username of logged-in user
 * @param auth.token {String} Generated token from backend
 * @param auth.type {String} Type of logged-in user
 * @param id {Number} The id of the suggestion
 * @param word {String} The (maybe) changed word
 * @param count {Number} The (maybe) changed count of suggestions
 * @returns {Promise<void | String>} void if action was successful
 */
function editSuggestionRequest(auth, id, word, count) {
    return fetch(window.api + `/api/admin/suggestions/edit?requestingUser=${auth?.username}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        },
        body: JSON.stringify({
            id: id,
            word: word,
            count: count
        })
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Sends a delete request for a suggestion to the backend to delete it from the database.
 * Returns an error as string if something went wrong.
 * @param auth {Object} The object containing all auth information
 * @param auth.username {String} Username of logged-in user
 * @param auth.token {String} Generated token from backend
 * @param auth.type {String} Type of logged-in user
 * @param id {Number} The id of the suggestion
 * @returns {Promise<void | String>} void if action was successful
 */
function deleteSuggestionRequest(auth, id) {
    return fetch(window.api + `/api/admin/suggestions/delete?requestingUser=${auth?.username}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        },
        body: JSON.stringify({
            id: id
        })
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Get all accounts for admin.
 * Returns an error as string if something went wrong.
 * @param auth {Object} The object containing all auth information
 * @param auth.username {String} Username of logged-in user
 * @param auth.token {String} Generated token from backend
 * @param auth.type {String} Type of logged-in user
 * @returns {Promise<String[] | String>} List of all accounts or error
 */
function getAccountsRequest(auth) {
    return fetch(window.api + `/api/admin/login/all?requestingUser=${auth?.username}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        }
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return data.logins
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Sends an account to the backend to be added to database.
 * Returns an error as string if something went wrong.
 * @param auth {Object} The object containing all auth information
 * @param auth.username {String} Username of logged-in user
 * @param auth.token {String} Generated token from backend
 * @param auth.type {String} Type of logged-in user
 * @param username {String} The username of the account
 * @param password {String} The password of the account
 * @param type {String} The type of the account
 * @returns {Promise<void | String>} void if action was successful
 */
function addAccountRequest(auth, username, password, type) {
    return fetch(window.api + `/api/admin/login/create?requestingUser=${auth?.username}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        },
        body: JSON.stringify({
            username: username,
            password: password,
            type: type
        })
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Sends an edit request for an account to the backend to edit in the database.
 * Returns an error as string if something went wrong.
 * @param auth {Object} The object containing all auth information
 * @param auth.username {String} Username of logged-in user
 * @param auth.token {String} Generated token from backend
 * @param auth.type {String} Type of logged-in user
 * @param id {Number} The id of the suggestion
 * @param username {String} The username of the account
 * @param password {String} The password of the account
 * @param type {String} The type of the account
 * @returns {Promise<void | String>} void if action was successful
 */
function editAccountRequest(auth, id, username, password, type) {
    return fetch(window.api + `/api/admin/login/edit?requestingUser=${auth?.username}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        },
        body: JSON.stringify({
            id: id,
            username: username,
            password: password,
            type: type
        })
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Sends a delete request for a suggestion to the backend to delete it from the database.
 * Returns an error as string if something went wrong.
 * @param auth {Object} The object containing all auth information
 * @param auth.username {String} Username of logged-in user
 * @param auth.token {String} Generated token from backend
 * @param auth.type {String} Type of logged-in user
 * @param id {Number} The id of the account
 * @returns {Promise<void | String>} void if action was successful
 */
function deleteAccountRequest(auth, id) {
    return fetch(window.api + `/api/admin/login/delete?requestingUser=${auth?.username}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth?.token
        },
        body: JSON.stringify({
            id: id
        })
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

export {
    getWordsWithIdsRequest,
    addWordRequest,
    editWordRequest,
    deleteWordRequest,
    getSuggestionsWithIdsRequest,
    addSuggestionRequest,
    addSuggestionToWordsRequest,
    editSuggestionRequest,
    deleteSuggestionRequest,
    getAccountsRequest,
    addAccountRequest,
    editAccountRequest,
    deleteAccountRequest
}