/**
 * Get all words for bingo.
 * Returns an error as string if something went wrong.
 * @returns {Promise<{id: Number, word: String, category: String}[] | String>}
 */
function getWordsRequest() {
    return fetch(window.api + '/api/bingo/words', {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return data
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

/**
 * Suggest a new word for the bingo.
 * Returns error as string.
 * @param word {String} The word that is being requested
 * @returns {Promise<void | String>}
 */
function suggestWordRequest(word) {
    return fetch(window.api + '/api/bingo/suggest', {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            word: word
        })
    }).then((response) => {
        return response.json().then((data) => {
            if (response.ok) {
                return
            }
            if (typeof data.message === 'string') return data.message
            return 'Something went wrong'
        })
    }).catch((err) => {
        console.log(err)
        console.error()
        return 'Something went wrong'
    })
}

export {getWordsRequest, suggestWordRequest}