import style from './PopupMessage.module.css'
import {createContext, useState} from 'react'
import {PopupMessageType} from '../../../util/enums'

const PopupMessageContext = createContext({})

/**
 * Provider context for showing error messages.
 * Creates a message box, where errors are shown.
 * <p>
 * Usage: <WordsProvider> ... </WordsProvider>
 * @param children {JSX.Element | JSX.Element[]} The components inside this context
 * @returns {JSX.Element} Provider Component for errors
 * @constructor
 */
export function PopupMessageProvider({children}) {
    const [messages, setMessages] = useState([])

    /**
     * Shows a message for 3s.
     * Message can be an error or an success message.
     * If another message is currently being shown, the new message is shown under the current message.
     * @param message {String} The error that needs to be shown
     * @param type {PopupMessageType} Needs to be either 'error' or 'success'
     */
    function showMessage(message, type) {
        setMessages(prevMessages => [...prevMessages, {message: message, type: type}])
        setTimeout(() => setMessages(prevMessages => prevMessages.slice(1)), 3000)
    }

    return (
        <PopupMessageContext.Provider value={{showMessage}}>
            {children}
            <div className={style.base}>
                <div className={style.msgBox}>
                    {messages?.map((message, i) => (
                        <div
                            className={message.type === PopupMessageType.ERROR ? style.error_message : style.success_message}
                            key={i}>
                            {message.message}
                        </div>
                    ))}
                </div>
            </div>
        </PopupMessageContext.Provider>
    )
}

export default PopupMessageContext