import style from './Suggestions.module.css'
import Popup from '../../components/popup/Popup'
import {useEffect, useRef, useState} from 'react'
import {getSuggestionsWithIdsRequest} from '../../accessors/AdminAccessor'
import usePopupMessage from '../../components/hooks/usePopupMessage'
import useAuth from '../../components/hooks/useAuth'
import {PopupMessageType, PopupType} from '../../util/enums'

/**
 * The page which shows all suggestions (only for admin).
 * @returns {JSX.Element} Suggestions page component
 * @constructor
 */
function Suggestions() {
    const {showMessage} = usePopupMessage()
    const {auth} = useAuth()

    const popupRef = useRef('')

    const [suggestions, setSuggestions] = useState([])
    const [show, setShow] = useState(false)
    const [suggestionPopupData, setSuggestionPopupData] = useState({id: -1, word: '', count: 0})

    // Set show popup to true or false
    const handleHide = () => setShow(false)
    const handleShow = () => setShow(true)

    // Load suggestions on start
    useEffect(() => {
        refreshSuggestions()
    }, [])

    /**
     * Load suggestions from backend.
     */
    function refreshSuggestions() {
        getSuggestionsWithIdsRequest(auth).then((suggestionsFromBackend) => {
            if (typeof suggestionsFromBackend === 'string') {
                showMessage(suggestionsFromBackend, PopupMessageType.ERROR)
            } else {
                setSuggestions(suggestionsFromBackend)
            }
        })
    }

    /**
     * Set which popup is shown.
     * @param popup {PopupType} The popup type
     * @param suggestion {Object} The data to fill the inputs
     * @param suggestion.id {Number} ID of suggestion
     * @param suggestion.word {String} Suggested word
     * @param suggestion.count {Number} Count of suggestions for word
     */
    const setPopup = (popup, suggestion) => {
        popupRef.current = popup
        setSuggestionPopupData(suggestion)
        handleShow()
    }

    /**
     * Sets the current popup to add a suggestion.
     */
    function addSuggestion() {
        setPopup(PopupType.ADD_SUGGESTION, {id: -1, word: '', count: 0})
    }

    /**
     * Sets the current popup to convert a suggestion to a word.
     * @param suggestion {Object} The data to fill the inputs
     * @param suggestion.id {Number} ID of suggestion
     * @param suggestion.word {String} Suggested word
     * @param suggestion.count {Number} Count of suggestions for word
     * @param suggestion.category {String} The category of the possible word (default: "")
     */
    function addToWords(suggestion) {
        setPopup(PopupType.ADD_TO_WORDS, suggestion)
    }

    /**
     * Sets the current popup to edit a suggestion.
     * @param suggestion {Object} The data to fill the inputs
     * @param suggestion.id {Number} ID of suggestion
     * @param suggestion.word {String} Suggested word
     * @param suggestion.count {Number} Count of suggestions for word
     */
    function editSuggestion(suggestion) {
        setPopup(PopupType.EDIT_SUGGESTION, suggestion)
    }

    /**
     * Sets the current popup to delete a suggestion.
     * @param suggestion {Object} The data to fill the inputs
     * @param suggestion.id {Number} ID of suggestion
     * @param suggestion.word {String} Suggested word
     * @param suggestion.count {Number} Count of suggestions for word
     */
    function deleteSuggestion(suggestion) {
        setPopup(PopupType.DELETE_SUGGESTION, suggestion)
    }

    /**
     * Generates the rows with buttons for the suggestion table.
     * @returns {JSX.Element[] | undefined} undefined if suggestions is not loaded from backend
     * @constructor
     */
    function SuggestionRows() {
        return suggestions?.map((suggestion) => (
            <tr key={suggestion.id}>
                <td>{suggestion.word}</td>
                <td>{suggestion.count}</td>
                <td>
                    <div className={style.addButtonCell}>
                        <button className={style.addButton} onClick={() => addToWords({...suggestion, category: ''})}>Als Wort hinzufügen
                        </button>
                    </div>
                </td>
                <td>
                    <div className={style.buttonCell}>
                        <button onClick={() => editSuggestion(suggestion)}
                            style={{'borderColor': 'var(--green-color)'}}>Bearbeiten
                        </button>
                        <button onClick={() => deleteSuggestion(suggestion)}
                            style={{'borderColor': 'var(--red-color)'}}>Löschen
                        </button>
                    </div>
                </td>
            </tr>
        ))
    }

    return (
        <>
            <div className={style.suggestions}>
                <table className={style.admin_suggestions_table}>
                    <thead>
                        <tr>
                            <th>Wort</th>
                            <th>Counter</th>
                            <th></th>
                            <th>
                                <div className={style.buttonCellHeader}>
                                    <button onClick={addSuggestion}>Hinzufügen</button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <SuggestionRows/>
                    </tbody>
                </table>
            </div>
            <Popup show={show} handleHide={handleHide} popupData={suggestionPopupData} popupRef={popupRef}
                refreshFunction={refreshSuggestions}/>
        </>
    )
}

export default Suggestions