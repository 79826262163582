import {createContext, useState} from 'react'

const BoardHeightContext = createContext({})

/**
 * A provider context for the height of the bingo board.
 * <p>
 * Usage: <BoardHeightProvider> ... </BoardHeightProvider>
 * @param children {JSX.Element | JSX.Element[]} The components inside this context
 * @returns {JSX.Element} Provider Component for height of bingo board
 * @constructor
 */
export function BoardHeightProvider({children}) {
    const [height, setHeight] = useState(750)

    return (
        <BoardHeightContext.Provider value={{height, setHeight}}>
            {children}
        </BoardHeightContext.Provider>
    )
}

export default BoardHeightContext