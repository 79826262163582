import {createContext, useEffect, useState} from 'react'

const WordsContext = createContext({})

/**
 * A provider context for words in the bingo board.
 * <p>
 * Usage: <WordsProvider> ... </WordsProvider>
 * @param children {JSX.Element | JSX.Element[]} The components inside this context
 * @returns {JSX.Element} Provider Component for words
 * @constructor
 */
export function WordsProvider({children}) {
    const [size, setSize] = useState(5)
    const [allWords, setAllWords] = useState([])
    const [activeWords, setActiveWords] = useState([])
    const [currentWords, setCurrentWords] = useState([])
    const [rerender, setRerender] = useState(false)
    const [category, setCategory] = useState('')

    /**
     * Sets the currently active words.
     * Read every word and check if it is active.
     * Save all active words in String array for easier access.
     */
    useEffect(() => {
        setActiveWords(allWords.filter(currentWord => currentWord.active).map(currentWord => currentWord.word))
    }, [allWords])

    /**
     * Changes a word to active or not active.
     * @param word {String} The word that is being changed
     * @param active {Boolean} The new active value
     */
    function setActive(word, active) {
        setAllWords(allWords.map(currentWord => currentWord.word === word ? {
            ...currentWord,
            active: active
        } : currentWord))
    }

    /**
     * Transforms the active words into a hex id.
     * @returns {string} The hex id
     */
    function getActiveId() {
        let activeWordsId = ''

        for (const word of allWords) {
            activeWordsId += word.active ? '1' : '0'
        }

        return parseInt(activeWordsId, 2).toString(16)
    }

    /**
     * Updates allWords.
     * Sets everyWord which index is 1 in bits to active and every other to not active.
     * @param bits {String} A bit episode which represents the active words
     */
    function updateAllActiveWords(bits) {
        const words = allWords

        for (let i = 0; i < bits.length; i++) {
            words[i] = {
                ...words[i],
                active: bits[i] === '1'
            }
        }

        setAllWords(words)
        setActiveWords(words.filter(currentWord => currentWord.active).map(currentWord => currentWord.word))
        setRerender(!rerender)
    }

    return (
        <WordsContext.Provider value={{
            size,
            allWords,
            activeWords,
            currentWords,
            rerender,
            category,
            setSize,
            setAllWords,
            setActiveWords,
            setCurrentWords,
            setCategory,
            setActive,
            getActiveId,
            updateAllActiveWords
        }}>
            {children}
        </WordsContext.Provider>
    )
}

export default WordsContext