import {createContext, useState} from 'react'

const AuthContext = createContext({})

/**
 * A provider context for authentication functionality.
 * e.g. if someone is allowed to few a page.
 * <p>
 * Usage: <AuthProvider> ... </AuthProvider>
 * @param children {JSX.Element | JSX.Element[]} The components inside this context
 * @returns {JSX.Element} Provider Component for authentication
 * @constructor
 */
export function AuthProvider({children}) {
    const [auth, setAuth] = useState({
        username: localStorage.getItem('drachen_username'),
        token: localStorage.getItem('drachen_token'),
        type: localStorage.getItem('drachen_type')
    })

    /**
     * Save login in local storage of browser
     * @param auth {Object} The object containing all auth information
     * @param auth.username {String} Username of logged-in user
     * @param auth.token {String} Generated token from backend
     * @param auth.type {String} Type of logged-in user
     */
    function login(auth) {
        localStorage.setItem('drachen_username', auth.username)
        localStorage.setItem('drachen_token', auth.token)
        localStorage.setItem('drachen_type', auth.type)
        setAuth(auth)
    }

    /**
     * Deletes login from local storage of browser
     */
    function logout() {
        localStorage.removeItem('drachen_username')
        localStorage.removeItem('drachen_token')
        localStorage.removeItem('drachen_type')
        setAuth(null)
    }

    return (
        <AuthContext.Provider value={{auth, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext