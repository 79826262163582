import {Navigate, Outlet} from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

/**
 * Component for forcing a type to be required for a route
 * @param allowedType {String} The type that is required
 * @returns {JSX.Element} RequireAuth Component
 * @constructor
 */
function RequireAuth({allowedType}) {
    const {auth} = useAuth()

    return (
        auth?.type === allowedType
            ? <Outlet/>
            : <Navigate to={'/login'} replace/>
    )
}

export default RequireAuth