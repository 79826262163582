import {NavLink, Outlet, useLocation, useNavigate} from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import style from './Navigation.module.css'
import logo from '../../images/logo.svg'

/**
 * A Navigation-Bar to navigate between subpages.
 * @returns {JSX.Element} Navbar component
 * @constructor
 */
function Navigation() {
    const {logout} = useAuth()
    const navigate = useNavigate()
    const location = useLocation()

    /**
     * Generate an array of navbar buttons.
     * @returns {JSX.Element[]} The navbar buttons as array
     */
    function NavLinks() {
        const navLinks = []
        let navButtons = []

        if (location.pathname.startsWith('/admin')) {
            navButtons = [
                {
                    word: 'Vorschläge',
                    path: '/admin/suggestions'
                },
                {
                    word: 'Wörter',
                    path: '/admin/words'
                },
                {
                    word: 'Nutzer',
                    path: '/admin/accounts'
                }
            ]
        } else {
            navButtons = []
        }

        for (let i = 0; i < navButtons.length; i++) {
            navLinks.push(<NavLink key={i} to={navButtons[i].path}>{navButtons[i].word}</NavLink>)
        }

        return navLinks
    }

    return (
        <>
            <div className={style.links}>
                <img className={style.homeButton} src={logo} alt={'Bingo'} onClick={() => navigate('/bingo')}/>
                <NavLinks/>
                <button onClick={logout} className={style.logout}>Abmelden</button>
            </div>
            <Outlet/>
        </>
    )
}

export default Navigation