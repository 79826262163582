import {useContext} from 'react'
import WordsContext from '../context/bingo/WordsProvider'

/**
 * Accessor for the bingo board words context.
 * @returns {{}} WordsContext export values
 */
function useWords() {
    return useContext(WordsContext)
}

export default useWords