import stylePopup from '../Popup.module.css'
import {useEffect, useRef, useState} from 'react'
import usePopupMessage from '../../hooks/usePopupMessage'
import useAuth from '../../hooks/useAuth'
import Dropdown from '../../dropdown/Dropdown'
import {hash} from '../../../util/Hashing'
import {addAccountRequest} from '../../../accessors/AdminAccessor'
import {PopupMessageType} from '../../../util/enums'

/**
 * A Component to show a popup, which lets an admin add an account.
 * @param account {Object} Starting values
 * @param account.id {Number} ID of account
 * @param account.username {String} Username of account
 * @param account.type {String} Type of account
 * @param typeOptions {String[]} All available types
 * @param hide {Function} Function to hide the popup
 * @param refresh {Function} Function to rerender the page
 * @returns {JSX.Element} AddAccountPopup component
 * @constructor
 */
function AddAccountPopup({account, typeOptions, hide, refresh}) {
    const {auth} = useAuth()
    const {showMessage} = usePopupMessage()

    const usernameRef = useRef()
    const passwordRef = useRef()

    const [type, setType] = useState(account.type)

    // Set starting values
    useEffect(() => {
        usernameRef.current.value = account.username
    }, [])

    /**
     * Sends request to backend.
     * Show status message on error or success.
     */
    function addAccount() {
        hash(passwordRef.current.value).then((hashedPw) => {
            addAccountRequest(auth, usernameRef.current.value, hashedPw, type).then((result) => {
                if (typeof result === 'string') {
                    showMessage(result, PopupMessageType.ERROR)
                } else {
                    showMessage('Nutzer hinzugefügt', PopupMessageType.SUCCESS)
                    hide() // Close popup
                    refresh() // Rerender
                }
            })
        })
    }

    return (
        <>
            <h1>Nutzer hinzufügen</h1>
            <div className={stylePopup.values}>
                <input placeholder={'Benutzername'} type='text' ref={usernameRef}/>
                <input placeholder={'Passwort'} type='password' ref={passwordRef}/>
                <Dropdown options={typeOptions} onChange={(currentType) => setType(currentType.value)} value={type}/>
            </div>
            <div className={stylePopup.submitButtons}>
                <button className={stylePopup.cancel} onClick={hide}>Abbrechen</button>
                <button className={stylePopup.submit} onClick={addAccount}>Hinzufügen</button>
            </div>
        </>
    )
}

export default AddAccountPopup