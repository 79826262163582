import stylePopup from '../Popup.module.css'
import {useEffect, useRef} from 'react'
import {addSuggestionRequest} from '../../../accessors/AdminAccessor'
import usePopupMessage from '../../hooks/usePopupMessage'
import useAuth from '../../hooks/useAuth'
import {PopupMessageType} from '../../../util/enums'

/**
 * A Component to show a popup, which lets an admin add a suggestion.
 * @param suggestion {Object} Starting values
 * @param suggestion.id {Number} ID of suggestion
 * @param suggestion.word {String} Suggested word
 * @param suggestion.count {Number} Count of suggestions for word
 * @param hide {Function} Function to hide the popup
 * @param refresh {Function} Function to rerender the page
 * @returns {JSX.Element} AddSuggestionPopup component
 * @constructor
 */
function AddSuggestionPopup({suggestion, hide, refresh}) {
    const {auth} = useAuth()
    const {showMessage} = usePopupMessage()

    const wordRef = useRef()
    const countRef = useRef()

    // Set starting values
    useEffect(() => {
        wordRef.current.value = suggestion.word
        countRef.current.value = suggestion.count
    }, [])

    /**
     * Sends request to backend.
     * Show status message on error or success.
     */
    function addSuggestion() {
        addSuggestionRequest(auth, wordRef.current.value, countRef.current.value).then((result) => {
            if (typeof result === 'string') {
                showMessage(result, PopupMessageType.ERROR)
            } else {
                showMessage('Vorschlag hinzugefügt', PopupMessageType.SUCCESS)
                hide() // Close popup
                refresh() // Rerender
            }
        })
    }

    return (
        <>
            <h1>Vorschlag hinzufügen</h1>
            <div className={stylePopup.values}>
                <input placeholder={'Wort'} type='text' ref={wordRef}/>
                <input type='number' placeholder="Anzahl" ref={countRef}/>
            </div>
            <div className={stylePopup.submitButtons}>
                <button className={stylePopup.cancel} onClick={hide}>Abbrechen</button>
                <button className={stylePopup.submit} onClick={addSuggestion}>Hinzufügen</button>
            </div>
        </>
    )
}

export default AddSuggestionPopup