import style from './BingoBoard.module.css'
import useWords from '../../hooks/useWords'
import Cell from './Cell'
import useBoardHeight from '../../hooks/useBoardHeight'
import {useResizeDetector} from 'react-resize-detector'
import {useEffect} from 'react'

/**
 * A bingo board with clickable cells.
 * @returns {JSX.Element} BingoBoard Component
 * @constructor
 */
function BingoBoard() {
    const {size} = useWords()
    const {setHeight} = useBoardHeight()
    const {width, height, ref} = useResizeDetector()

    useEffect(() => {
        setHeight(height)
    }, [width, height])

    /**
     * Create the rows of the bingo board.
     * @returns {JSX.Element[]} The rows as an array
     */
    function createRows() {
        const rows = []

        for (let i = 0; i < size; i++) {
            const elements = []

            // Create one row
            for (let j = 0; j < size; j++) {
                elements.push(<Cell key={'e' + (i * size + j)} index={i * size + j}/>)
            }

            rows.push(<tr key={'r' + i}>{elements}</tr>)
        }

        return rows
    }


    return (
        <table ref={ref} className={style.bingo_table}>
            <tbody>
                {createRows()}
            </tbody>
        </table>
    )
}

export default BingoBoard