import './index.css'
import {Navigate, Route, Routes} from 'react-router-dom'
import Bingo from './pages/bingo/Bingo'
import RequireAuth from './components/context/auth/RequireAuth'
import Navigation from './components/navigation/Navigation'
import Words from './pages/admin/Words'
import Suggestions from './pages/admin/Suggestions'
import Login from './pages/login/Login'
import {AuthProvider} from './components/context/auth/AuthProvider'
import {PopupMessageProvider} from './components/context/popup_message/PopupMessageProvider'
import {WordsProvider} from './components/context/bingo/WordsProvider'
import Accounts from './pages/admin/Accounts'
import {BoardStateProvider} from './components/context/bingo/BoardStateProvider'

/**
 * Creates The main app.
 * Initializes auth, error and words provider.
 * Initializes routing.
 * @returns {JSX.Element} App component
 * @constructor
 */
function App() {
    return (
        <AuthProvider>
            <PopupMessageProvider>
                <WordsProvider>
                    <BoardStateProvider>
                        <Routes>
                            <Route path='/'>
                                <Route path='bingo' Component={Bingo}/>

                                <Route path='login' Component={Login}/>

                                <Route element={<RequireAuth allowedType={'admin'}/>}>
                                    <Route path='admin/' Component={Navigation}>
                                        <Route path='suggestions' Component={Suggestions}/>
                                        <Route path='words' Component={Words}/>
                                        <Route path='accounts' Component={Accounts}/>

                                        <Route index element={<Navigate to={'/admin/suggestions'}/>}/>
                                        <Route path='*' element={<Navigate to={'/admin/suggestions'}/>}/>
                                    </Route>
                                </Route>

                                <Route index element={<Navigate to={'/bingo'}/>}/>
                                <Route path='*' element={<Navigate to={'/bingo'}/>}/>
                            </Route>
                        </Routes>
                    </BoardStateProvider>
                </WordsProvider>
            </PopupMessageProvider>
        </AuthProvider>
    )
}

export default App