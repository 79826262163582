const PopupMessageType = {
    ERROR: 'error',
    SUCCESS: 'success'
}

const PopupType = {
    ADD_WORD: 'addWord',
    EDIT_WORD: 'editWord',
    DELETE_WORD: 'deleteWord',
    ADD_SUGGESTION: 'addSuggestion',
    ADD_TO_WORDS: 'addToWords',
    EDIT_SUGGESTION: 'editSuggestion',
    DELETE_SUGGESTION: 'deleteSuggestion',
    ADD_ACCOUNT: 'addAccount',
    EDIT_ACCOUNT: 'editAccount',
    DELETE_ACCOUNT: 'deleteAccount',
}

export {PopupMessageType, PopupType}