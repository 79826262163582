import style from './Dropdown.module.css'
import {useEffect, useRef, useState} from 'react'

function Dropdown({options, onChange, value, reset = true}) {
    const [active, setActive] = useState(false)
    const [text, setText] = useState()
    const [current, setCurrent] = useState()
    const [width, setWidth] = useState(100)

    const dropdownButton = useRef()
    const dropdownItem = useRef()

    useEffect(() => {
        const windowClick = (e) => {
            if (e.target === dropdownButton.current) return
            if (e.target === dropdownItem.current) return
            setActive(false)
        }
        window.addEventListener('click', windowClick)
        return () => {
            window.removeEventListener('click', windowClick)
        }
    }, [])

    useEffect(() => {
        if (!options) return
        if (options.length <= 0) options.push({text: 'Keine Optionen', value: undefined})
        submit(reset ? 0 : options.map(option => option.value).indexOf(current))

        // Get longest word and adjust length if needed
        const length = options.map((option) => option.text).reduce(function(a, b) {
            return a.length > b.length ? a : b
        }, '').length
        setWidth(length <= 7 ? 100 : (length - 7) * 10 + 100)
    }, [options])

    useEffect(() => {
        if (!value) return
        submit(options.map(option => option.value).indexOf(value))
    }, [value])

    const submit = (index) => {
        if (index === -1) index = 0
        setText(options[index].text)
        setCurrent(options[index].value)
        onChange(options[index])
        setActive(false)
    }

    const toggle = () => {
        setActive(!active)
    }

    return (
        <div className={style.dropdown}>
            <button className={style.select} style={{width: `${width}px`}} onClick={toggle} ref={dropdownButton}>
                <p className={style.text}>{text}</p>
                <p className={style.arrow}>▾</p>
            </button>
            <ul className={style.options} style={{display: active ? 'block' : 'none'}}>
                {options.map((option, index) => (
                    <li key={index}>
                        <label onClick={() => submit(index)} ref={dropdownItem}>{option.text}</label>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Dropdown