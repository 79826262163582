import stylePopup from '../Popup.module.css'
import {deleteWordRequest} from '../../../accessors/AdminAccessor'
import usePopupMessage from '../../hooks/usePopupMessage'
import useAuth from '../../hooks/useAuth'
import {PopupMessageType} from '../../../util/enums'

/**
 * A Component to show a popup, which lets an admin delete a word.
 * @param word {Object} Starting values
 * @param word.id {Number} ID of word
 * @param word.word {String} The word
 * @param hide {Function} Function to hide the popup
 * @param refresh {Function} Function to rerender the page
 * @returns {JSX.Element} DeleteWordPopup component
 * @constructor
 */
function DeleteWordPopup({word, hide, refresh}) {
    const {auth} = useAuth()
    const {showMessage} = usePopupMessage()

    /**
     * Sends request to backend.
     * Show status message on error or success.
     */
    function deleteWord() {
        deleteWordRequest(auth, word.id).then((result) => {
            if (typeof result === 'string') {
                showMessage(result, PopupMessageType.ERROR)
            } else {
                showMessage('Wort gelöscht', PopupMessageType.SUCCESS)
                hide() // Close popup
                refresh() // Rerender
            }
        })
    }

    return (
        <>
            <h1>Wort löschen</h1>
            <p>Möchtest du dieses Wort löschen?</p>
            <p>{word.word}</p>
            <div className={stylePopup.submitButtons}>
                <button className={stylePopup.cancel} onClick={hide}>Abbrechen</button>
                <button className={stylePopup.submit} onClick={deleteWord}>Löschen</button>
            </div>
        </>
    )
}

export default DeleteWordPopup