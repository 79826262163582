import stylePopup from '../Popup.module.css'
import {useEffect, useRef, useState} from 'react'
import {addSuggestionToWordsRequest} from '../../../accessors/AdminAccessor'
import usePopupMessage from '../../hooks/usePopupMessage'
import useAuth from '../../hooks/useAuth'
import {PopupMessageType} from '../../../util/enums'

/**
 * A Component to show a popup, which lets an admin convert a suggestion to a word.
 * @param suggestion {Object} Starting values
 * @param suggestion.id {Number} ID of suggestion
 * @param suggestion.word {String} Suggested word
 * @param suggestion.count {Number} Count of suggestions for word
 * @param suggestion.category {String} The category of the word
 * @param hide {Function} Function to hide the popup
 * @param refresh {Function} Function to rerender the page
 * @returns {JSX.Element} SuggestionToWordPopup component
 * @constructor
 */
function SuggestionToWordPopup({suggestion, hide, refresh}) {
    const {auth} = useAuth()
    const {showMessage} = usePopupMessage()

    const wordRef = useRef()
    const categoryRef = useRef()

    const [warning, setWarning] = useState(null)

    // Set starting values
    useEffect(() => {
        wordRef.current.value = suggestion.word
        categoryRef.current.value = suggestion.category
    }, [])

    /**
     * Check for correct values everytime a value is changed
     */
    function valueChanged() {
        if (!wordRef.current.value) {
            setWarning('Kein Wort angegeben')
        } else if (!categoryRef.current.value) {
            setWarning('Keine Kategorie angegeben')
        } else {
            setWarning(null)
        }
    }

    /**
     * Sends request to backend.
     * Show status message on error or success.
     */
    function editWord() {
        addSuggestionToWordsRequest(auth, suggestion.id, wordRef.current.value, categoryRef.current.value).then((result) => {
            if (typeof result === 'string') {
                showMessage(result, PopupMessageType.ERROR)
            } else {
                showMessage('Vorschlag zu Wörtern hinzugefügt', PopupMessageType.SUCCESS)
                hide() // Close popup
                refresh() // Rerender
            }
        })
    }

    return (
        <>
            <h1>Vorschlag bearbeiten</h1>
            <p>Möchtest du diesen Vorschlag zu den Wörtern hinzufügen?</p>
            <div className={stylePopup.values}>
                <input placeholder={'Wort'} type='text' ref={wordRef} onChange={valueChanged}/>
                <input placeholder={'Kategorie'} type='text' ref={categoryRef} onChange={valueChanged}/>
            </div>
            <p>Anzahl: {suggestion.count}</p>
            {warning && <label className={stylePopup.warning}>{warning}</label>}
            <div className={stylePopup.submitButtons}>
                <button className={stylePopup.cancel} onClick={hide}>Abbrechen</button>
                <button className={stylePopup.submit} disabled={!!warning} onClick={editWord}>Hinzufügen</button>
            </div>
        </>
    )
}

export default SuggestionToWordPopup