import stylePopup from '../Popup.module.css'
import {deleteAccountRequest} from '../../../accessors/AdminAccessor'
import usePopupMessage from '../../hooks/usePopupMessage'
import useAuth from '../../hooks/useAuth'
import {PopupMessageType} from '../../../util/enums'

/**
 * A Component to show a popup, which lets an admin add an account.
 * @param account {Object} Starting values
 * @param account.id {Number} ID of account
 * @param account.username {String} Username of account
 * @param account.type {String} Type of account
 * @param typeOptions {String[]} All available types
 * @param hide {Function} Function to hide the popup
 * @param refresh {Function} Function to rerender the page
 * @returns {JSX.Element} AddAccountPopup component
 * @constructor
 */
function DeleteAccountPopup({account, hide, refresh}) {
    const {auth} = useAuth()
    const {showMessage} = usePopupMessage()

    /**
     * Sends request to backend.
     * Show status message on error or success.
     */
    function deleteAccount() {
        deleteAccountRequest(auth, account.id).then((result) => {
            if (typeof result === 'string') {
                showMessage(result, PopupMessageType.ERROR)
            } else {
                showMessage('Nutzer gelöscht', PopupMessageType.SUCCESS)
                hide() // Close popup
                refresh() // Rerender
            }
        })
    }

    return (
        <>
            <h1>Account löschen</h1>
            <p>Möchtest du diesen Account löschen?</p>
            <p>{account.username}</p>
            <div className={stylePopup.submitButtons}>
                <button className={stylePopup.cancel} onClick={hide}>Abbrechen</button>
                <button className={stylePopup.submit} onClick={deleteAccount}>Löschen</button>
            </div>
        </>
    )
}

export default DeleteAccountPopup