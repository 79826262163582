import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import {BrowserRouter} from 'react-router-dom'

/* eslint-disable no-undef */
// Set ip either to local backend or prod backend
switch (process.env.REACT_APP_ENV) {
    case 'dev':
        window.api = process.env.REACT_APP_BACKEND_DEV
        break

    case 'prod':
        window.api = process.env.REACT_APP_BACKEND_PROD
        break
}
/* eslint-enable no-undef */

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
)