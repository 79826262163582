import {useContext} from 'react'
import BingoContext from '../context/bingo/BoardStateProvider'

/**
 * Accessor for the bingo state context.
 * @returns {{}} BoardContext export values
 */
function useBingoState() {
    return useContext(BingoContext)
}

export default useBingoState